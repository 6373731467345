import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login.vue'
// import Exercise from '../views/Exercise.vue'
// import Problem from '../views/Problem.vue'
import ProblemEdit from '../views/ProblemEdit.vue'
import ProblemEdit2 from '../views/ProblemEdit2.vue'
import ProblemEdit3 from '../views/ProblemEdit3.vue'
// import RegisterPremium from '../views/RegisterPremium.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  // {
  //   path: '/exercise',
  //   name: 'Exercise',
  //   component: Exercise
  // },
  // {
  //   path: '/problem',
  //   name: 'Problem',
  //   component: Problem
  // },
  {
    path: '/problemEdit',
    name: 'Problem Edit',
    component: ProblemEdit
  },
  {
    path: '/ProblemEdit2',
    name: 'Problem Edit 2',
    component: ProblemEdit2
  },
  {
    path: '/ProblemEdit3',
    name: 'Problem Edit 3',
    component: ProblemEdit3
  },
  // {
  //   path: '/RegisterPremium',
  //   name: 'Register Premium',
  //   component: RegisterPremium
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router

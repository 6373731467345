<template>
  <b-container>
    <b-sidebar
      id="sidebar-ppid"
      title="PPID"
      shadow
    >
      <div class="px-3 py-2">
        <!-- <div class="container scroll" v-if="pids.length > 0"> -->
        <b-form-input v-model="ppid_input" id="input-ppid"></b-form-input>
        <!-- <b-form-datalist id="input-ppid" :options="ppids"></b-form-datalist> -->
        <br>
        <button class="button" @click="getPpid">
          Get PPID
        </button>
        <hr>
        <b-form-group label="pids" v-if="pids.length > 0">
          <b-form-radio-group
            id="radio-pid"
            v-model="pid_input"
            :options="pids"
            button-variant="outline-info"
            buttons
            stacked
            name="radio-pid"
          ></b-form-radio-group>
        </b-form-group>
        <!-- </div> -->
      </div>
    </b-sidebar>

    
    <b-sidebar
      id="sidebar-assesment"
      title="Assesment"
      right
      shadow
    >
      <div class="px-3 py-2">
        
        <b-form-group label="Scoring Type">
          <b-form-radio-group
            id="radio-score-type"
            v-model="score_type"
            name="radio-score-type"
            :options="score_type_options">
          </b-form-radio-group>
        </b-form-group>
        
        <b-form-group label="Solution">
          <b-row  v-for="(option, index) in options" :key="index">
            <b-col sm="5">
              <label for="`input-score-${index}`">Option {{ ['A','B','C','D','E','F','G','H','I','J','K','L','M','N'][index] }}</label>
            </b-col>
            <b-col sm="7">
              <b-form-radio
                v-if="score_type==='SA'"
                :id="`input-score-${index}`"
                name="option-radios"
                class="d-flex"
                v-model="solution"
                :value="index"></b-form-radio>
              <b-form-input
                v-if="score_type==='MA'"
                :id="`input-score-${index}`"
                name="option-score"
                class="d-flex"
                v-model="solution_scores[index]"
                size="sm"></b-form-input>
            </b-col>
          </b-row>
        </b-form-group>
        

      </div>

      <template v-slot:footer>
       <div class="px-3 py-2">
        <b-button @click="updateSolution">Save assesment</b-button>
       </div>
      </template>

    </b-sidebar>

    <!-- <b-row
      id="row-main-nav"
      align-h="between"
    >
      <b-col cols="auto">
        <b-button style="margin: 4px;" v-b-toggle.sidebar-ppid>PPID</b-button>
      </b-col>
      <b-col cols="auto">
        <b-form-group>
          <b-form-radio-group
            id="btn-main-select"
            v-model="main_select"
            :options="main_options"
            buttons
            name="btn-main-select"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col cols="auto">
        <b-button style="margin: 4px;" v-b-toggle.sidebar-assesment>Assesment</b-button>
      </b-col>
    </b-row> -->
    
    <!-- <br> -->

    <div 
      id="row-problem-list"
      class="overflow-auto"
      v-if="pids.length>0"
    >
      <b-form-group>
        <b-form-radio-group
          id="radio-pid-no"
          v-model="pid_input"
          :options="pidOptions"
          button-variant="outline-info"
          buttons
          name="radio-pid"
        ></b-form-radio-group>
      </b-form-group>
    </div>

    <hr>
    <div v-if="pid!=null">
      <b-row
        id="row-problem-edit"
        v-if="main_select=='problem'"
      >
        <b-col>
          <default-editor
            v-if="question"
            :name="'Soal'"
            :input="question"
            @output="json => setProblemDescription(json)">
          </default-editor>
          <!-- <b-form-group label="Tingkat kesulitan">
            <b-form-select
              v-if="pid!=null"
              name="option-radios"
              class="d-flex"
              v-model="difficulty_selection"
              :options="difficulty_options"
            ></b-form-select>
          </b-form-group> -->
          <b-form-group label="Tipe Soal">
            <b-form-select
              v-if="pid!=null"
              name="option-radios"
              class="d-flex"
              v-model="subject_selection"
              :options="subject_options"
            ></b-form-select>
            <!-- <b-form-select
              v-if="subject_selection!=null"
              name="option-radios"
              class="d-flex"
              v-model="chapter_selection"
              :options="chapter_options[subject_selection]"
            ></b-form-select>
            <b-form-select
              v-if="subject_selection!=null &&chapter_selection!=null "
              name="option-radios"
              class="d-flex"
              v-model="section_selection"
              :options="section_options[subject_selection][chapter_selection]"
            ></b-form-select> -->
          </b-form-group>
          
          
          <!-- <default-editor
            v-if="tempExplanation"
            :name="'Pembahasan'"
            :input="tempExplanation"
            @output="json => setTempExplanation(json)">
          </default-editor> -->
          
          <!-- <b-form-group label="Scoring Type">
            <b-form-radio-group
              id="radio-score-type"
              v-model="score_type"
              name="radio-score-type"
              :options="score_type_options">
            </b-form-radio-group>
          </b-form-group> -->
          <!-- <hr> -->

          <div class="export" v-if="editor.problem">
            <!-- <h3>JSON</h3>
            <pre><code v-html="editor.problem.description"></code></pre> -->

            <!-- <h3>HTML</h3>
            <pre><code>{{ html }}</code></pre> -->
          </div>
          <!-- <b-form-checkbox
            id="checkbox-has_article"
            v-model="has_article"
            name="checkbox-has_article"
            :value="true"
            :unchecked-value="false"
          > enable article
          </b-form-checkbox>
          <default-editor
            v-if="has_article"
            :name="'Article'"
            :input="article"
            @output="json => setProblemArticle(json)">
          </default-editor> -->
        </b-col>

        <b-col>
          <default-editor
            v-for="(option, index) in options" :key="index"
            :name="`Option ${['A','B','C','D','E','F','G','H','I','J','K','L','M','N'][index]} `"
            :input="options[index]"
            @output="json => setProblemOption([index, json])">
          </default-editor>
          <b-form-group label="Kunci Jawaban">
            <b-row  v-for="(option, index) in options" :key="index">
              <b-col sm="5">
                <label for="`input-score-${index}`">Option {{ ['A','B','C','D','E','F','G','H','I','J','K','L','M','N'][index] }}</label>
              </b-col>
              <b-col sm="7">
                <b-form-radio
                  v-if="score_type==='SA'"
                  :id="`input-score-${index}`"
                  name="option-radios"
                  class="d-flex"
                  v-model="solution"
                  :value="index"></b-form-radio>
                <b-form-input
                  v-if="score_type==='MA'"
                  :id="`input-score-${index}`"
                  name="option-score"
                  class="d-flex"
                  v-model="solution_scores[index]"
                  size="sm"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <!-- <b-button @click="updateProblem">Save Problem</b-button> -->
          <b-button @click="save">Simpan</b-button>
        </b-col>
        
      </b-row>

      <b-row
        id="row-explamation-edit"
        v-else-if="main_select=='explanation' && explanation"
      >
        <b-col cols="6">
          
          <b-form inline @submit.stop.prevent="handleSubmit">
            <b-input
              id="explanationTitle-input"
              v-model="explanation_title_input"
              class="mb-2 mr-sm-2 mb-sm-0"
              placeholder="konsep / cara cepat / ..."
            ></b-input>
            <b-button variant="primary" @click="addExplanationName">Tambah</b-button>
          </b-form>

          <hr>

          <b-list-group
            id="explanation-list"
            v-if="explanation > 0">
            <b-list-group-item
              :active="selected_explanation==title"
              v-for="title in explanation"
              :key="title"
              class="d-flex justify-content-between align-items-center">
              <span
                style="cursor: pointer;"
                @click="selected_explanation=title"
              >{{title}}</span>
              <b-icon
                icon="x"
                @click="removeExplanationName(title)"
                style="cursor: pointer;"></b-icon>
              <!-- <b-badge variant="primary" pill>x</b-badge> -->
            </b-list-group-item>
            
          </b-list-group>

          <hr>

          <b-button @click="updateExplanation">Save Explanation</b-button>
        </b-col>

        <b-col cols="6">

          <span v-if="selected_explanation">
            <b-form-select
              id="sb-locales"
              size="sm"
              v-model="explanation_type_input"
              :options="explanation_type_options"></b-form-select>
            <b-form-spinbutton
              v-if="explanation_type_input=='steps'"
              id="sb-explanation_steps_length"
              size="sm" 
              v-model="explanation_steps_length"
              @change="setExplanationStepLength([title, explanation_steps_length])"
              inline></b-form-spinbutton>

            <br>

            <default-editor
              v-for="(step, index) in explanation_steps" :key="index"
              :name="`Langkah ${index+1}`"
              :input="step"
              @output="json => setExplanationStep([selected_explanation, index, json])">
            </default-editor>
          </span>
          
        </b-col>
        
      </b-row>

      <b-row
        id="row-explamation-edit"
        v-else-if="main_select=='tempexplanation' && explanation"
      >
        <b-col cols="6">
          
          <default-editor
            v-if="tempExplanation"
            :name="'Pembahasan'"
            :input="tempExplanation"
            @output="json => setTempExplanation(json)">
          </default-editor>
          
          <b-button @click="updateTempExplanation">Save Explanation</b-button>
          
        </b-col>
        
      </b-row>
    </div>

  </b-container>
</template>

<script>
/* eslint-disable no-console */

import { mapState, mapMutations, mapActions } from 'vuex'
// import { db } from '../db'

import DefaultEditor from '../components/DefaultEditor'

export default {
  components: {
    DefaultEditor
  },
  computed: {
    ...mapState([
      'pid',
      'ppid',
      'doc',
      'editor']),
    ...mapState({
      pids: state => state.doc.problemPack.pids,
      problem: state => state.editor.problem,
      problemSolution: state => state.editor.problemSolution,
      explanation: state => state.editor.explanation.contents,
    }),
    pidOptions: function(){
      return this.pids.map((pid, idx)=>{ return { text: `${idx+1}`, value: pid } })
    },
    explanationModalAddState() {
      const name = this.explanationModalInput
      return name.length>0
        ? !Object.keys(this.explanation).includes(name)
        : null
    },
    explanationModalDeleteState() {
      const name = this.explanationModalInput
      return name.length>0
        ? Object.keys(this.explanation).includes(name)
        : null
    }
  },
  data() {
    return {
      main_select: "problem",
      main_options: [
        { text: 'Soal', value: 'problem' },
        // { text: 'Pembahasan', value: 'explanation' },
        { text: 'Pembahasan', value: 'tempexplanation' }
      ],


      difficulty_selection: null,
      difficulty_options: [
        { value: 0, text: '⭐' },
        { value: 1, text: '⭐⭐' },
        { value: 2, text: '⭐⭐⭐' },
      ],
      subject_selection: null,
      subject_options: [
        { value: "Faraidl", text: 'Faraidl' },
        { value: "Sains Islam", text: 'Sains Islam' },
        { value: "Tarikh", text: 'Tarikh' },
        { value: "Tajwid", text: 'Tajwid' },
        { value: "Nahwu Shorof", text: 'Nahwu Shorof' },
        { value: "Aqidah Akhlaq", text: 'Aqidah Akhlaq' },
        { value: "Fiqih", text: 'Fiqih' },
        { value: "Al-Qur'an Hadits", text: 'Al-Qur\'an Hadits' },
      ],
      chapter_selection: null,
      chapter_options: {
        "Matematika": [
          { value: "Aljabar", text: "Aljabar" },
          { value: "Kalkulus", text: "Kalkulus" },
          { value: "Geometri", text: "Geometri" },
          { value: "Kombinatorika", text: "Kombinatorika" },
          { value: "Teori Bilangan", text: "Teori Bilangan" },
        ],
        "Kimia": [
          { value: "Redoks dan elektrokimia", text: "Redoks dan elektrokimia" },
          { value: "Larutan", text: "Larutan" },
          { value: "Teori atom + Hitungan", text: "Teori atom + Hitungan" },
          { value: "Senyawa Organik", text: "Senyawa Organik" },
        ],
        "Fisika": [
          { value: "Kinematika", text: "Kinematika" },
          { value: "Dinamika", text: "Dinamika" },
          { value: "Listrik Magnet", text: "Listrik Magnet" },
          { value: "Termofisika", text: "Termofisika" },
          { value: "Optika", text: "Optika" },
          { value: "Gelombang", text: "Gelombang" },
        ],
        "Biologi": [
          { value: "Sel", text: "Sel" },
          { value: "Organ dan Sistem organ", text: "Organ dan Sistem organ" },
          { value: "Mikrobiologi", text: "Mikrobiologi" },
          { value: "Sistem Kardiovaskular", text: "Sistem Kardiovaskular" },
          { value: "Sistem Gerak", text: "Sistem Gerak" },
          { value: "Genetika", text: "Genetika" },
        ],
        "Teknik Biomedis": [
          { value: "PTB", text: "PTB" },
          { value: "Logika", text: "Logika" },
          { value: "Elektonika Digital", text: "Elektonika Digital" },
          { value: "Fisiologi", text: "Fisiologi" },
          { value: "Elektronika Analog", text: "Elektronika Analog" },
          { value: "Anatomi", text: "Anatomi" },
          { value: "Fisika Medis", text: "Fisika Medis" },
          { value: "Biomaterial", text: "Biomaterial" },
        ]
      },
      section_selection: null,
      section_options: {
        "Matematika": {
          "Aljabar": [
            { value: "Polinomial", text: "Polinomial" },
            { value: "Fungsi", text: "Fungsi" },
            { value: "Matriks", text: "Matriks" },
            { value: "Fungsi Eksponensial", text: "Fungsi Eksponensial" },
            { value: "Logaritma", text: "Logaritma" },
          ],
          "Kalkulus": [
            { value: "Integral", text: "Integral" },
            { value: "Diferensial", text: "Diferensial" },
            { value: "Limit", text: "Limit" },
            { value: "Trigonometri", text: "Trigonometri" },
          ],
          "Geometri": [
            { value: "Vektor", text: "Vektor" },
            { value: "Bangun datar", text: "Bangun datar" },
            { value: "Bangun ruang", text: "Bangun ruang" },
          ],
          "Kombinatorika": [
            { value: "Peluang", text: "Peluang" },
            { value: "Kombinasi", text: "Kombinasi" },
          ],
          "Teori Bilangan": [
            { value: "Modulo", text: "Modulo" },
          ],
        },
        "Kimia": {
          "Redoks dan elektrokimia": [
            { value: "Hk. Faraday", text: "Hk. Faraday" },
            { value: "Elektrolisis", text: "Elektrolisis" },
            { value: "Redoks", text: "Redoks" },
          ],
          "Larutan": [
            { value: "Perhitungan pH campuran", text: "Perhitungan pH campuran" },
            { value: "Titrasi", text: "Titrasi" },
            { value: "Kesetimbangan larutan", text: "Kesetimbangan larutan" },
            { value: "Sifat koligatif", text: "Sifat koligatif" },
          ],
          "Teori atom + Hitungan": [
            { value: "Teori atom", text: "Teori atom" },
            { value: "Bilangan Kuantum", text: "Bilangan Kuantum" },
          ],
          "Senyawa Organik": [
            { value: "Struktur tata nama senyawa", text: "Struktur tata nama senyawa" },
            { value: "Reaksi senyawa", text: "Reaksi senyawa" },
          ],
        },
        "Fisika": {
          "Kinematika": [
            { value: "Gerak lurus", text: "Gerak lurus" },
            { value: "Gerak melingkar", text: "Gerak melingkar" },
            { value: "Parabola", text: "Parabola" },
          ],
          "Dinamika": [
            { value: "Hk.Newton", text: "Hk.Newton" },
            { value: "Momentum dan impuls", text: "Momentum dan impuls" },
            { value: "Dinamika Fluida", text: "Dinamika Fluida" },
          ],
          "Listrik Magnet": [
            { value: "Listrik Statis", text: "Listrik Statis" },
            { value: "Listrik Dinamis", text: "Listrik Dinamis" },
            { value: "Medan Magnet", text: "Medan Magnet" },
          ],
          "Termofisika": [
            { value: "Kalor", text: "Kalor" },
            { value: "Hukum Termodinamika", text: "Hukum Termodinamika" },
          ],
          "Optika": [
            { value: "Lensa", text: "Lensa" },
            { value: "Cermin", text: "Cermin" },
          ],
          "Gelombang": [
            { value: "Osilasi", text: "Osilasi" },
            { value: "Gelombang berjalan", text: "Gelombang berjalan" },
            { value: "Superposisi", text: "Superposisi" },
          ],
        },
        "Biologi": {
          "Sel": [
            { value: "Struktur dan fungsi sel", text: "Struktur dan fungsi sel" },
            { value: "Reproduksi sel", text: "Reproduksi sel" },
            { value: "Metabolisme sel", text: "Metabolisme sel" },
          ],
          "Organ dan Sistem organ": [
            { value: "Struktur dan fungsi sistem organ", text: "Struktur dan fungsi sistem organ" },
            { value: "Penyakit pada sistem organ", text: "Penyakit pada sistem organ" },
          ],
          "Mikrobiologi": [
            { value: "Virus", text: "Virus" },
            { value: "Bakteri", text: "Bakteri" },
          ],
          "Sistem Kardiovaskular": [
            { value: "Pembuluh darah", text: "Pembuluh darah" },
            { value: "Jantung", text: "Jantung" },
            { value: "Siklus jantung", text: "Siklus jantung" },
            { value: "Siklus darah", text: "Siklus darah" },
          ],
          "Sistem Gerak": [
            { value: "Sifat-sifat dan kerja otot manusia", text: "Sifat-sifat dan kerja otot manusia" },
            { value: "Rangka manusia", text: "Rangka manusia" },
            { value: "Persendian", text: "Persendian" },
          ],
          "Genetika": [
            { value: "Pola hereditas", text: "Pola hereditas" },
            { value: "Hukum Mendel", text: "Hukum Mendel" },
          ],
        },
        "Teknik Biomedis": {
          "PTB": [
            { value: "Clinical Engineer", text: "Clinical Engineer" },
            { value: "Hospital Engineer", text: "Hospital Engineer" },
            { value: "Biolistrik", text: "Biolistrik" },
            { value: "Peran Teknik Biomedis", text: "Peran Teknik Biomedis" },
            { value: "Fenomena medis dalam sudut pandang Fisika", text: "Fenomena medis dalam sudut pandang Fisika" },
          ],
          "Logika": [
            { value: "Propositional Logic", text: "Propositional Logic" },
            { value: "Predicate Logic", text: "Predicate Logic" },
            { value: "Formal Number Theory", text: "Formal Number Theory" },
          ],
          "Elektonika Digital": [
            { value: "Gerbang Logika", text: "Gerbang Logika" },
            { value: "Konversi Bilangan Digital", text: "Konversi Bilangan Digital" },
            { value: "Dasar aljabar Boolean", text: "Dasar aljabar Boolean" },
          ],
          "Fisiologi": [
            { value: "Respirasi", text: "Respirasi" },
            { value: "Neurologi", text: "Neurologi" },
            { value: "Endokrin", text: "Endokrin" },
          ],
          "Elektronika Analog": [
            { value: "Filter", text: "Filter" },
            { value: "Rangkaian RLC seri & paralel", text: "Rangkaian RLC seri & paralel" },
            { value: "Resistor", text: "Resistor" },
            { value: "Dioda", text: "Dioda" },
          ],
          "Anatomi": [
            { value: "Limfaticus", text: "Limfaticus" },
            { value: "Skeletal", text: "Skeletal" },
            { value: "Musculus", text: "Musculus" },
            { value: "Digestivus", text: "Digestivus" },
          ],
          "Fisika Medis": [
            { value: "Biofluida", text: "Biofluida" },
            { value: "Instrumentasi diagnosis", text: "Instrumentasi diagnosis" },
            { value: "Radiasi", text: "Radiasi" },
            { value: "Biomekanika", text: "Biomekanika" },
          ],
          "Biomaterial": [
            { value: "Struktur Kristal", text: "Struktur Kristal" },
            { value: "Aplikasi biomat", text: "Aplikasi biomat" },
            { value: "Sifat biomat", text: "Sifat biomat" },
            { value: "Sintesis biomat", text: "Sintesis biomat" },
            { value: "Diagram fasa", text: "Diagram fasa" },
          ],
        },
      },

      ppid_options: [],
      ppid_input: this.ppid,
      pid_input: this.pid,
      
      score_type_options: [
        {text:"Single Score", value:"SA"},
        {text:"Multiple Score", value:"MA"}
      ],
      score_type: "SA",
      solution: null,
      solution_scores: [],

      has_article: false,
      article: {},
      question: {},
      tempExplanation: {},
      options: [],

      selected_explanation: null,
      explanation_title_input: '',
      explanation_type_options: [
        {text:"Deskripsi", value:"single"},
        {text:"Step-by-step", value:"steps"}
      ],
      explanation_type_input: '',
      explanation_steps: [],
      explanation_steps_length: 0,
    }
  },
  methods: {
    ...mapMutations([
      'setPid',
      'setPpid',

      'setProblemPack',

      'setProblem',
      'setProblemDescription',
      'setProblemOption',
      'setProblemArticle',
      'enableProblemArticle',
      'setProblemDifficulty',
      'setProblemSubject',
      'setProblemChapter',
      'setProblemSection',

      'setProblemSolution',

      'setTempExplanation',

      'addExplanationContent',
      'removeExplanationContent',
      'setExplanationStepLength',
      'setExplanationStep'
    ]),
    ...mapActions([
      'getProblemPack',
      'getProblem',
      'addProblemPackPid',
      'updateProblem',
      'updateSolution',
      'updateExplanation',
      'updateTempExplanation'
    ]),
    getPpid() {
      this.setPpid(this.ppid_input)
      this.getProblemPack()
    },
    
    addExplanationName(){
      if(this.explanation.findIndex((e) => e.title == this.explanation_title_input) != -1){
        alert("Nama Harus Beda")
      } else {
        this.addExplanationContent(this.explanation_title_input)
        this.explanation_title_input = ""
      }
    },
    removeExplanationName(explanation_title){
      this.removeExplanationContent(explanation_title)
      if(explanation_title==this.selected_explanation){
        this.explanation_title_input = ""
      }
    },
    save(){
      this.updateProblem()
      this.updateSolution()
    }

    // resetModal() {
    //   this.explanationModalInput = ''
    // },
    // handleExplanationAddOk(bvModalEvt) {
    //   bvModalEvt.preventDefault()
    //   this.handleExplanationAddSubmit()
    // },
    // handleExplanationAddSubmit() {
    //   if (this.explanationModalAddState) {
    //     this.addExplanationContent(this.explanationModalInput)
    //     this.$nextTick(() => this.$bvModal.hide('modal-explanation-add') )
    //   }
    // }
  },
  watch : {
    'doc.problem':function(val) {
      this.article = val.article ?? {}
      this.has_article = val.has_article ?? false
      this.question = val.description ?? {}
      this.options = val.options ?? []
      this.difficulty_selection = val.difficulty ?? null
      this.subject_selection = val.subject ?? null
      this.chapter_selection = val.chapter ?? null
      this.section_selection = val.section ?? null
    },
    'doc.tempExplanation':function(val) {
      this.tempExplanation = val
    },
    'doc.problemSolution':function(val) {
      this.solution = val.optionCorrect.findIndex((x) => x==1 )
      this.solution_scores = val.optionCorrect.map(x=>x+1)
    },
    'has_article':function(val) {
      this.enableProblemArticle(val)
    },
    'doc.explanation':function() {
      this.selected_explanation = null
    },
    'selected_explanation':function(val) {
      if(this.explanation[val]){
        this.explanation_steps = this.explanation[val].steps
        this.explanation_steps_length = this.explanation_steps.length
      }
    },
    ppid:function(val) {
      this.ppid_input = val
      this.setPid(null)
    },
    pid:function(val) {
      this.pid_input = val
    },
    pid_input:function(val) {
      this.setPid(val)
      this.getProblem()
      this.selected_explanation = null
      this.explanation_steps = []
      this.explanation_title_input = ''
      this.explanation_steps_length = 0
    },
    solution:function(val) {
      let optionCorrect = [0,0,0,0]
      Array.from(Array(this.options.lenth)).forEach(()=>{
        optionCorrect.push(0)
      })
      optionCorrect[val] = 1
      this.setProblemSolution({
        optionCorrect: optionCorrect
      })
    },
    solution_scores:function(val) {
      let optionCorrect = val
      this.setProblemSolution({
        optionCorrect: optionCorrect.map(x=>x-1)
      })
    },
    difficulty_selection:function(val) {
      if(val!=null){
        this.setProblemDifficulty(val)
      }
    },
    subject_selection:function(val) {
      if(val!=null){
        this.setProblemSubject(val)
      }
    },
    chapter_selection:function(val) {
      if(val!=null){
        this.setProblemChapter(val)
      }
    },
    section_selection:function(val) {
      if(val!=null){
        this.setProblemSection(val)
      }
    }
  },
  created() {
    this.setPpid("#p2021.isef.to2")
    this.getProblemPack()
    // this.setPpid("9sdCcoC4BDvxyR6DxDzB")
    // this.setPid("UKFNARktASoKBpFepvSf")
    // this.getProblemPack()
    // this.getProblem()
    // this.pid_input = this.pid
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variables.scss";

*{
  text-align:left
}

.actions {
  max-width: 30rem;
  margin: 0 auto 2rem auto;
}
.export {
  max-width: 30rem;
  margin: 0 auto 2rem auto;
  pre {
    padding: 1rem;
    border-radius: 5px;
    font-size: 0.8rem;
    font-weight: bold;
    background: rgba($color-black, 0.05);
    color: rgba($color-black, 0.8);
  }
  code {
    display: block;
    white-space: pre-wrap;
  }
}
.scroll {
    max-height: 100px;
    overflow-y: auto;
}
</style>